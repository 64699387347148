<template>
    <StepContainerInner2 class="space-y-4 relative">
        <div
            v-if="recipientSearchStore.searchState !== GeolocState.START"
            class="absolute top-5 left-5 cursor-pointer text-primary"
            @click="recipientSearchStore.reset"
        >
            <i
                class="fi fi-rs-angle-left mr-1 translate-y-0.5 inline-block text-sm"
            ></i>
            <span class="text-sm">{{ t('user.common.back') }}</span>
        </div>

        <form
            @submit.prevent="
                recipientSearchStore.reset(), onAddressFormSubmit()
            "
            class="space-y-4"
        >
            <StepInstructions class="font-[600]">
                {{ t(props.instructionTKey) }}
            </StepInstructions>

            <AVInput
                v-model="address"
                name="address"
                prefix-img="rs-marker"
                :placeholder="
                    t('user.campaign.geolocation_address_placeholder')
                "
            />

            <AVButton
                class="w-full !mt-2"
                type="submit"
                :button-type="
                    recipientSearchStore.searchState ===
                    GeolocState.SELECT_RECIPIENT
                        ? 'outline-primary'
                        : 'primary'
                "
            >
                {{ t('user.actions.search') }}
            </AVButton>

            <div
                v-if="
                    recipientSearchStore.searchState ===
                    GeolocState.VALIDATE_ADDRESS
                "
                class="space-y-4 text-center"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_your_address') }}
                </div>
                <AVSelectButtons
                    class="!mt-2 text-left"
                    :vertical="true"
                    :multiple="false"
                    :options="
                        recipientSearchStore.addressSearchResults.map(
                            (address, idx) => ({
                                value: idx,
                                label: address.properties.label,
                            })
                        )
                    "
                    @update:model-value="
                        recipientSearchStore.selectAddress($event as number)
                    "
                    v-bind:name="''"
                    :model-value="undefined"
                />
            </div>
        </form>

        <div
            v-if="
                recipientSearchStore.searchState ===
                GeolocState.SELECT_RECIPIENT
            "
        >
            <div
                v-if="recipientsInCampaign.length > 0"
                class="flex flex-col space-y-2"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_recipient') }}
                </div>
                <RecipientSelectorWithInfos
                    v-for="(recipient, index) in recipientsInCampaign"
                    :key="index"
                    :recipient="recipient"
                    class="cursor-pointer"
                    @click="onChooseRecipient(recipient)"
                />
            </div>
            <div v-else-if="recipientSearchStore.hasErrored">
                {{ t('user.campaign.geolocation_load_error') }}
            </div>
            <div
                v-else-if="
                    isRequestDone &&
                    recipientSearchStore.recipientSearchResults &&
                    recipientsInCampaign.length === 0
                "
            >
                {{ t('user.campaign.geolocation_no_result') }}
            </div>
        </div>

        <div
            v-if="recipientSearchStore.isLoading"
            class="flex flex-col justify-center items-center"
        >
            <AVLoader />
            {{ t('user.campaign.geolocation_loading') }}
        </div>
    </StepContainerInner2>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onUnmounted, ref, watch } from 'vue'
import { ModelId } from '@/models/types'
import { GeolocState } from '@/AppCampaigns/types'
import {
    useRecipientSearchStore,
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '../../stores'
import { RecipientPublic, RecipientSimple } from '@/models/recipients'
import StepInstructions from '../StepInstructions.vue'
import StepContainerInner2 from '../StepContainerInner2.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVLoader from '@/components/AVLoader.vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import { useI18n } from 'vue-i18n'
import { TKey } from '@/i18n'
import RecipientSelectorWithInfos from '@/AppCampaigns/components/RecipientSelectorWithInfos.vue'

const { t } = useI18n()

export interface Props {
    instructionTKey: TKey
}

const props = withDefaults(defineProps<Props>(), {
    instructionTKey: 'user.campaign.geolocation_enter_your_address',
})

const recipientSearchStore = useRecipientSearchStore()
const wizardStore = useSendCampaignWizardStore()
const store = useSendCampaignStore()

const address = ref<string | null>(null)
const isRequestDone = ref(false)

const rlistIds = computed<Array<ModelId>>(() =>
    store.campaign!.interpellations.flatMap((interpellation) =>
        interpellation.recipient_lists
            .map((rlist) => rlist.id)
            .filter((id): id is string => !!id)
    )
)

const onAddressFormSubmit = async () => {
    if (!address.value) {
        return
    }
    await recipientSearchStore.searchAddress(address.value, rlistIds.value)
    isRequestDone.value = true
}

const recipientsInCampaign = computed(() =>
    recipientSearchStore.recipientSearchResults
        ? store.destinatariesInCampaign(
              recipientSearchStore.recipientSearchResults as Array<RecipientSimple>
          )
        : []
)

const onChooseRecipient = (recipient: RecipientPublic) => {
    wizardStore.$reset(null, 'StepGeolocation')
    store.setRecipient(recipient)
    if (store.interpellationModesAvailableForSelectedRecipients.length == 1) {
        store.setInterpellationMode(
            store.interpellationModesAvailableForSelectedRecipients[0]
        )
    }
    wizardStore.goToNextStep()
}

watch(address, () => {
    if (isRequestDone.value) {
        isRequestDone.value = false
        recipientSearchStore.reset()
    }
})

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
})

onUnmounted(() => {
    recipientSearchStore.reset()
})
</script>
